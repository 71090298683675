/* eslint-disable import/prefer-default-export */

import { SPECIALTIES, SELECTED_SPECIALTY } from '../constants';

export function setSpecialties({ specialties }) {
  return {
    type: SPECIALTIES,
    specialties,
  };
}

export function setSelectedSpecialty({ specialty }) {
  return {
    type: SELECTED_SPECIALTY,
    selectedSpecialty: specialty,
  };
}
